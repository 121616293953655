<sh-modal
  id="contactModal"
  [label]="'Contact Information' | translate"
  modal-height=""
  modal-width="500px"
>
  <sh-divider></sh-divider>
  <sh-table *ngIf="contactInfo && contactInfo.length > 0">
    <sh-table-row *ngFor="let contact of contactInfo">
      <sh-table-cell>
        <sh-text size="title-1">{{ contact }}</sh-text>
      </sh-table-cell>
    </sh-table-row>
  </sh-table>
</sh-modal>

<sh-tooltip
  target="target-1"
  label="Toggle Theme"
  placement="left"
></sh-tooltip>
<sh-tooltip
  target="target-2"
  label="Contact Information"
  placement="left"
></sh-tooltip>

<sh-access-bar [label]="'ExpertGuidance' | translate">
  <sh-icon
    button
    icon="disc-space-3"
    id="target-1"
    data-toggle="tooltip"
    label="Toggle Theme"
    slot="functions"
    (click)="onThemeToggleClick($event)"
  ></sh-icon>

  <sh-icon
    button
    icon="envelope"
    id="target-2"
    data-toggle="tooltip"
    slot="functions"
    onclick="document.querySelector('#contactModal').visible = true"
  >
  </sh-icon>
</sh-access-bar>
